import React from 'react';

import { SimplePercent } from '@profitfy/pakkun-icons';
import { ETextWeight } from '@domain/enums/components/EText';
import { IConfigShippingModalProps } from '@domain/interfaces/dashboard/Shipping/IConfigShippingModal';
import { currencyFormatter } from '@helpers/masks';
import { useShipping } from '@helpers/hooks/pages/dashboard/shipping/useShipping';
import { EShippingAmountType, EShippingType } from '@domain/enums/dashboard/shipping/EShipping';

import * as S from './styles';

const ConfigShippingLineItemModal: React.FC<IConfigShippingModalProps> = ({ isOpen, toggle }) => {
  const {
    shippingSingleProductType,
    setShippingSingleProductType,
    shippingDuoProductType,
    setShippingDuoProductType,
    shippingSingleAmountType,
    shippingDuoAmountType,
    handleShippingSingleAmountType,
    handleShippingDuoAmountType,
    shippingSingleAmount,
    shippingDuoAmount,
    setShippingSingleAmount,
    setShippingDuoAmount,
  } = useShipping();

  // const [amount, setAmount] = React.useState<string>('');
  const [singleAmount, setSingleAmount] = React.useState<string>('');
  const [duoAmount, setDuoAmount] = React.useState<string>('');

  // const [singleProductShippingType, setSingleProductShippingType] = React.useState<EShippingType>(
  //   EShippingType.INTEGRATION,
  // );
  // const [duoProductShippingType, setDuoProductShippingType] = React.useState<EShippingType>(
  //   EShippingType.INTEGRATION,
  // );

  const handleSingleProductShippingType = React.useCallback(
    event => {
      const type = event.target.value as EShippingType;

      if (type !== EShippingType.AVERAGE_BY_ORDER) {
        setSingleAmount('');
      }

      setShippingSingleProductType(type);
    },
    [setShippingSingleProductType],
  );

  const handleDuoProductShippingType = React.useCallback(
    event => {
      const type = event.target.value as EShippingType;

      if (type !== EShippingType.AVERAGE_BY_ORDER) {
        setSingleAmount('');
      }

      setShippingDuoProductType(type);
    },
    [setShippingDuoProductType],
  );

  const onChangeSingle = React.useCallback(event => {
    const formattedAmount = currencyFormatter(event.target.value);

    setSingleAmount(formattedAmount);
  }, []);

  const onChangeDuo = React.useCallback(event => {
    const formattedAmount = currencyFormatter(event.target.value);

    setDuoAmount(formattedAmount);
  }, []);

  const onSingleProductChange = React.useCallback(
    event => {
      handleShippingSingleAmountType(event.target.value);
    },
    [handleShippingSingleAmountType],
  );

  const onDuoProductChange = React.useCallback(
    event => {
      handleShippingDuoAmountType(event.target.value);
    },
    [handleShippingDuoAmountType],
  );

  const onConfirm = React.useCallback(() => {
    setShippingSingleAmount(singleAmount);
    setShippingDuoAmount(duoAmount);
    toggle();
  }, [singleAmount, duoAmount, toggle, setShippingSingleAmount, setShippingDuoAmount]);

  React.useEffect(() => {
    if (shippingSingleAmount && shippingDuoAmount) {
      setSingleAmount(shippingSingleAmount);
      setDuoAmount(shippingDuoAmount);
    }
  }, [shippingSingleAmount, shippingDuoAmount]);

  return (
    <S.Modal isOpen={isOpen} toggle={toggle}>
      <S.HeadingWrapper>
        <SimplePercent size={18} />
        <S.Heading weight={ETextWeight.MEDIUM}>
          Configuração de frete pela quantidade de produtos
        </S.Heading>
      </S.HeadingWrapper>

      <S.Label>Para pedidos com apenas um produto</S.Label>

      <S.ItemQuantityWrapper>
        <S.InputGroup>
          <S.Label>Como será contabilizado para pedidos com apenas um produto</S.Label>

          <S.Select
            name="single-type"
            onChange={handleSingleProductShippingType}
            value={shippingSingleProductType}
          >
            <S.Option value={EShippingType.INTEGRATION}>Nenhuma configuração</S.Option>
            <S.Option value={EShippingType.AVERAGE_BY_ORDER}>Frete médio por venda</S.Option>
            <S.Option value={EShippingType.PAID_BY_CUSTOMER}>
              Frete pago pelo cliente no checkout
            </S.Option>
          </S.Select>
        </S.InputGroup>

        {shippingSingleProductType === EShippingType.AVERAGE_BY_ORDER && (
          <S.Row>
            <S.InputGroup flexNumber={1}>
              <S.Label>Tipo</S.Label>

              <S.Select
                name="amount-type"
                onChange={onSingleProductChange}
                value={shippingSingleAmountType}
              >
                <S.Option value={EShippingAmountType.FIXED}>Fixo</S.Option>
                <S.Option value={EShippingAmountType.PERCENTAGE}>Porcentagem</S.Option>
              </S.Select>
            </S.InputGroup>

            <S.InputGroup flexNumber={2}>
              <S.Label>Valor de frete por venda concluída</S.Label>
              <S.Input
                type="text"
                value={singleAmount}
                noFeedbackError
                onChange={onChangeSingle}
                placeholder="Digite o valor do frete"
                prependText={shippingSingleAmountType === EShippingAmountType.FIXED ? 'R$' : '%'}
              />
            </S.InputGroup>
          </S.Row>
        )}
      </S.ItemQuantityWrapper>
      <br />

      <S.Label>Para pedidos com mais de um produto</S.Label>
      <S.ItemQuantityWrapper>
        <S.InputGroup>
          <S.Label>Como será contabilizado para pedidos com mais de um produto</S.Label>

          <S.Select
            name="duo-type"
            onChange={handleDuoProductShippingType}
            value={shippingDuoProductType}
          >
            <S.Option value={EShippingType.INTEGRATION}>Nenhuma configuração</S.Option>
            <S.Option value={EShippingType.AVERAGE_BY_ORDER}>Frete médio por venda</S.Option>
            <S.Option value={EShippingType.PAID_BY_CUSTOMER}>
              Frete pago pelo cliente no checkout
            </S.Option>
          </S.Select>
        </S.InputGroup>

        {shippingDuoProductType === EShippingType.AVERAGE_BY_ORDER && (
          <S.Row>
            <S.InputGroup flexNumber={1}>
              <S.Label>Tipo</S.Label>

              <S.Select
                name="amount-type"
                onChange={onDuoProductChange}
                value={shippingDuoAmountType}
              >
                <S.Option value={EShippingAmountType.FIXED}>Fixo</S.Option>
                <S.Option value={EShippingAmountType.PERCENTAGE}>Porcentagem</S.Option>
              </S.Select>
            </S.InputGroup>

            <S.InputGroup flexNumber={2}>
              <S.Label>Valor de frete por venda concluída</S.Label>
              <S.Input
                type="text"
                value={duoAmount}
                noFeedbackError
                onChange={onChangeDuo}
                placeholder="Digite o valor do frete"
                prependText={shippingDuoAmountType === EShippingAmountType.FIXED ? 'R$' : '%'}
              />
            </S.InputGroup>
          </S.Row>
        )}
      </S.ItemQuantityWrapper>

      <S.ButtonsWrapper>
        <S.CancelButton onClick={toggle}>Cancelar</S.CancelButton>
        <S.ConfirmButton onClick={onConfirm}>Confirmar</S.ConfirmButton>
      </S.ButtonsWrapper>
    </S.Modal>
  );
};

export default ConfigShippingLineItemModal;
