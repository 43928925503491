import React from 'react';

import Popover from '@components/common/core/Utils/Popover';
import { EHeadAlign } from '@domain/enums/components/ETable';
import { useDate } from '@helpers/hooks/useDate';
import { numberFormatter } from '@helpers/masks';
import { useShipping } from '@helpers/hooks/pages/dashboard/shipping/useShipping';
import DeleteModal from '@components/common/core/Utils/DeleteModal';

import { IShipping } from '@domain/interfaces/dashboard/Shipping/IShipping';
import { EShippingAmountType } from '@domain/enums/dashboard/shipping/EShipping';
import * as S from './styles';

interface IRowProps {
  shipping: IShipping;
}

const Row: React.FC<IRowProps> = ({ shipping }) => {
  const { format } = useDate();
  const { deleteHistoricShipping, mutateShippings } = useShipping();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState<boolean>(false);

  const handleDeleteModalOpen = React.useCallback(() => setIsDeleteModalOpen(!isDeleteModalOpen), [
    isDeleteModalOpen,
  ]);

  // const onEdit = React.useCallback(() => {
  //   handleVariantCostToEdit(variantCost);

  //   handleNextStep();
  // }, [handleNextStep, handleVariantCostToEdit, variantCost]);

  // const onDelete = React.useCallback(async () => {
  //   await deleteVariantCost(shipping.alias_id);
  // }, [variantCost, deleteVariantCost]);

  const handleDelete = React.useCallback(async () => {
    try {
      await deleteHistoricShipping(shipping.alias_id);
      handleDeleteModalOpen();
      mutateShippings();
    } catch (error) {
      console.log(error);
    }
  }, [deleteHistoricShipping, shipping, handleDeleteModalOpen, mutateShippings]);

  const type = shipping.amount_type === EShippingAmountType.FIXED ? 'R$' : '%';

  return (
    <S.TableRow>
      <S.TableData>
        {shipping.start_date ? format(new Date(shipping.start_date), 'dd/MM/yyyy') : '-'}
      </S.TableData>
      <S.TableData>
        {shipping.end_date ? format(new Date(shipping.end_date), 'dd/MM/yyyy') : '-'}
      </S.TableData>
      <S.TableData align={EHeadAlign.RIGHT}>
        {type} {numberFormatter(shipping.amount, 2)}
      </S.TableData>
      <S.TableData align={EHeadAlign.CENTER}>
        <>
          {!shipping.is_default && (
            <S.ActionsButton
              data-tip
              data-for={`historic-actions-${shipping.alias_id}`}
              data-event="click"
            >
              <S.Dot />
              <S.Dot />
              <S.Dot />

              <S.AccessibleActionsButtonName>Ações</S.AccessibleActionsButtonName>
            </S.ActionsButton>
          )}

          <Popover width={128} id={`historic-actions-${shipping.alias_id}`} place="bottom">
            <S.ActionsWrapper>
              <S.ActionButton name="delete" onClick={handleDeleteModalOpen}>
                Deletar
              </S.ActionButton>
            </S.ActionsWrapper>
          </Popover>

          <DeleteModal
            isLoading={false}
            content="Deseja excluir o histórico? Essa ação é irreversível."
            isOpen={isDeleteModalOpen}
            onDelete={handleDelete}
            title="Excluir histórico"
            toggle={handleDeleteModalOpen}
          />
        </>
      </S.TableData>
    </S.TableRow>
  );
};

export default Row;
