import React from 'react';
import ReactDOM from 'react-dom';
import Gleap from 'gleap';
import App from './App';

Gleap.initialize('HG8HSMkI6fyzB0Ghr1M5IgqxB3MOfLRu');

ReactDOM.render(
  <React.StrictMode>
    <App />,
  </React.StrictMode>,
  document.getElementById('root'),
);
