import React from 'react';

import { useParams } from 'react-router-dom';
import { IParams } from '@domain/interfaces/IParams';
import { useToast } from '@helpers/hooks/useToast';
import { EShippingAmountType, EShippingType } from '@domain/enums/dashboard/shipping/EShipping';
import { IShippingProvider } from '@domain/interfaces/dashboard/Shipping/IShipping';
import { currencyToNumber, numberFormatter } from '@helpers/masks';

import shippingService from '@services/pages/dashboard/shipping/shipping';

const ShippingContext = React.createContext<IShippingProvider | null>(null);

export const ShippingProvider: React.FC = ({ children }) => {
  const { toast } = useToast();
  const { storeAliasId } = useParams<IParams>();

  const [isSavingConfig, setIsSavingConfig] = React.useState<boolean>(false);
  const [isConfigShippingModalOpen, setIsConfigShippingModalOpen] = React.useState<boolean>(false);
  const [shippingAmount, setShippingAmount] = React.useState<string>('');
  const [amountType, setAmountType] = React.useState<EShippingAmountType>(
    EShippingAmountType.FIXED,
  );
  const [
    isConfigShippingLineItemModalOpen,
    setIsConfigShippingLineItemModalOpen,
  ] = React.useState<boolean>(false);

  const [shippingsPage, setShippingsPage] = React.useState<number>(0);
  const [shippingsPageCount, setShippingsPageCount] = React.useState<number>(0);

  const [shippingSingleProductType, setShippingSingleProductType] = React.useState<EShippingType>(
    EShippingType.INTEGRATION,
  );
  const [shippingDuoProductType, setShippingDuoProductType] = React.useState<EShippingType>(
    EShippingType.INTEGRATION,
  );

  const [
    shippingSingleAmountType,
    setShippingSingleAmountType,
  ] = React.useState<EShippingAmountType>(EShippingAmountType.FIXED);

  const [shippingDuoAmountType, setShippingDuoAmountType] = React.useState<EShippingAmountType>(
    EShippingAmountType.FIXED,
  );

  const [shippingSingleAmount, setShippingSingleAmount] = React.useState<string>('');
  const [shippingDuoAmount, setShippingDuoAmount] = React.useState<string>('');

  const handleShippingSingleAmountType = React.useCallback(newValue => {
    setShippingSingleAmountType(newValue);
  }, []);

  const handleShippingDuoAmountType = React.useCallback(newValue => {
    setShippingDuoAmountType(newValue);
  }, []);

  const {
    shipping,
    isLoading: isLoadingShipping,
    isValidating: isValidatingShipping,
    mutate: mutateShipping,
  } = shippingService.getShipping({ storeAliasId });

  const {
    shippings,
    isLoading: isLoadingShippings,
    isValidating: isValidatingShippings,
    mutate: mutateShippings,
    totalPages: shippingsTotalPages,
  } = shippingService.getShippings({ storeAliasId, page: shippingsPage });

  const handleShippingsPageChange = React.useCallback(
    ({ selected }) => setShippingsPage(selected),
    [],
  );

  const handleShippingAmount = React.useCallback(newValue => {
    setShippingAmount(newValue);
  }, []);

  const handleShippingAmountType = React.useCallback(newValue => {
    setAmountType(newValue);
  }, []);

  const handleConfigShippingModalOpen = React.useCallback(() => {
    setIsConfigShippingModalOpen(currentState => !currentState);
  }, []);

  const handleConfigShippingLineItemModalOpen = React.useCallback(() => {
    setIsConfigShippingLineItemModalOpen(currentState => !currentState);
  }, []);

  const deleteHistoricShipping = React.useCallback(
    async (shipping_alias_id: string) => {
      try {
        await shippingService.deleteShippingDefault({
          storeAliasId,
          shippingAliasId: shipping_alias_id,
        });

        toast.success('Frete deletado com sucesso.');
      } catch (error) {
        toast.success('Ocorreu um erro ao deletar o frete.');
      }
    },
    [storeAliasId, toast],
  );

  const deleteShipping = React.useCallback(async () => {
    setIsSavingConfig(true);

    try {
      if (!shipping?.alias_id) {
        setIsSavingConfig(false);
        return;
      }

      await shippingService.deleteShippingDefault({
        storeAliasId,
        shippingAliasId: shipping?.alias_id,
      });

      await mutateShipping();

      toast.success('Configuração de frete salva com sucesso.');

      setIsSavingConfig(false);
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
      setIsSavingConfig(false);
    }
  }, [mutateShipping, shipping, storeAliasId, toast]);

  const deleteAllShippings = React.useCallback(async () => {
    setIsSavingConfig(true);

    try {
      if (!shippings.length) {
        setIsSavingConfig(false);
        return;
      }

      const deleteShippingsPromise: any[] = [];

      shippings.forEach(s => {
        deleteShippingsPromise.push(
          shippingService.deleteShippingDefault({
            storeAliasId,
            shippingAliasId: s.alias_id,
          }),
        );
      });

      await Promise.all(deleteShippingsPromise);

      await mutateShippings();

      toast.success('Configuração de frete salva com sucesso.');

      setIsSavingConfig(false);
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
      setIsSavingConfig(false);
    }
  }, [mutateShippings, shippings, storeAliasId, toast]);

  const deleteAllShippingsHistoric = React.useCallback(async () => {
    setIsSavingConfig(true);

    try {
      if (!shippings.length) {
        setIsSavingConfig(false);
        return;
      }

      const deleteShippingsPromise: any[] = [];

      const historicShippings = shippings.filter(s => !s.is_default);

      historicShippings.forEach(s => {
        deleteShippingsPromise.push(
          shippingService.deleteShippingDefault({
            storeAliasId,
            shippingAliasId: s.alias_id,
          }),
        );
      });

      await Promise.all(deleteShippingsPromise);

      await mutateShippings();

      toast.success('Configuração de frete salva com sucesso.');

      setIsSavingConfig(false);
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
      setIsSavingConfig(false);
    }
  }, [mutateShippings, shippings, storeAliasId, toast]);

  const updatingShipping = React.useCallback(
    async shippingType => {
      setIsSavingConfig(true);

      try {
        if (shippingType === EShippingType.LINE_ITEM_QUANTITY) {
          await shippingService.updateShippingDefault({
            storeAliasId,
            shippingAliasId: shipping?.alias_id,
            data: {
              amount: 0,
              amount_type: amountType,
              type: shippingType,
              config: {
                single_product: {
                  type: shippingSingleProductType,
                  amount: currencyToNumber(shippingSingleAmount),
                  amount_type: shippingSingleAmountType,
                },
                duo_product: {
                  type: shippingDuoProductType,
                  amount: currencyToNumber(shippingDuoAmount),
                  amount_type: shippingDuoAmountType,
                },
              },
            },
          });
        } else {
          await shippingService.updateShippingDefault({
            storeAliasId,
            shippingAliasId: shipping?.alias_id,
            data: {
              amount:
                shippingType === EShippingType.AVERAGE_BY_ORDER
                  ? currencyToNumber(shippingAmount)
                  : 0,
              amount_type: amountType,
              type: shippingType,
            },
          });
        }

        await mutateShipping();

        toast.success('Configuração de frete salva com sucesso.');

        setIsSavingConfig(false);
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
        setIsSavingConfig(false);
      }
    },
    [
      amountType,
      toast,
      storeAliasId,
      mutateShipping,
      shipping,
      shippingAmount,
      shippingSingleProductType,
      shippingDuoProductType,
      shippingSingleAmount,
      shippingDuoAmount,
      shippingSingleAmountType,
      shippingDuoAmountType,
    ],
  );

  const createShipping = React.useCallback(
    async (shippingType: EShippingType) => {
      setIsSavingConfig(true);

      try {
        if (shippingType === EShippingType.LINE_ITEM_QUANTITY) {
          await shippingService.createShippingDefault({
            storeAliasId,
            data: {
              amount: 0,
              amount_type: amountType,
              type: shippingType,
              config: {
                single_product: {
                  type: shippingSingleProductType,
                  amount: currencyToNumber(shippingSingleAmount),
                  amount_type: shippingSingleAmountType,
                },
                duo_product: {
                  type: shippingDuoProductType,
                  amount: currencyToNumber(shippingDuoAmount),
                  amount_type: shippingDuoAmountType,
                },
              },
            },
          });
        } else {
          await shippingService.createShippingDefault({
            storeAliasId,
            data: {
              amount:
                shippingType === EShippingType.AVERAGE_BY_ORDER
                  ? currencyToNumber(shippingAmount)
                  : 0,
              amount_type: amountType,
              type: shippingType,
            },
          });
        }

        await mutateShipping();

        toast.success('Configuração de frete criada com sucesso.');

        setIsSavingConfig(false);
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
        setIsSavingConfig(false);
      }
    },
    [
      amountType,
      toast,
      storeAliasId,
      mutateShipping,
      shippingAmount,
      shippingSingleProductType,
      shippingDuoProductType,
      shippingSingleAmount,
      shippingDuoAmount,
      shippingSingleAmountType,
      shippingDuoAmountType,
    ],
  );

  const createHistoricShipping = React.useCallback(
    async ({
      shippingType,
      type,
      startDate,
      endDate,
      amount,
    }: {
      shippingType: EShippingType;
      type: EShippingAmountType;
      startDate: Date;
      endDate: Date;
      amount: string;
    }) => {
      try {
        await shippingService.createHistoricShipping({
          storeAliasId,
          data: {
            amount: currencyToNumber(amount),
            amount_type: type,
            type: shippingType,
            end_date: endDate,
            start_date: startDate,
          },
        });

        await mutateShippings();

        toast.success('Configuração de frete criada com sucesso.');

        setIsSavingConfig(false);
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
      }
    },
    [toast, storeAliasId, mutateShippings],
  );

  const upsertShipping = React.useCallback(
    (shippingType: EShippingType) => {
      if (shipping?.id) {
        updatingShipping(shippingType);
      } else {
        createShipping(shippingType);
      }
    },
    [updatingShipping, createShipping, shipping],
  );

  React.useEffect(() => {
    if (shipping && shipping?.type === EShippingType.AVERAGE_BY_ORDER) {
      const formattedAmount = numberFormatter(shipping?.amount, 2);

      setShippingAmount(formattedAmount);
      setAmountType(shipping.amount_type);
    } else {
      setShippingAmount('');
    }
  }, [shipping]);

  React.useEffect(() => {
    if (shipping && shipping?.type === EShippingType.LINE_ITEM_QUANTITY) {
      const singleShippingType = shipping.config?.single_product?.type;
      const duoShippingType = shipping.config?.duo_product?.type;
      const singleShippingAmount = numberFormatter(shipping.config?.single_product?.amount, 2);
      const duoShippingAmount = numberFormatter(shipping.config?.duo_product?.amount, 2);
      const singleShippingAmountType = shipping.config?.single_product?.amount_type;
      const duoShippingAmountType = shipping.config?.duo_product?.amount_type;

      setShippingSingleProductType(singleShippingType);
      setShippingDuoProductType(duoShippingType);
      setShippingSingleAmount(singleShippingAmount);
      setShippingDuoAmount(duoShippingAmount);
      setShippingSingleAmountType(singleShippingAmountType);
      setShippingDuoAmountType(duoShippingAmountType);
    }
  }, [shipping]);

  React.useEffect(() => {
    if (shippingsTotalPages) {
      setShippingsPageCount(shippingsTotalPages);
    }
  }, [shippingsTotalPages]);

  return (
    <ShippingContext.Provider
      value={{
        shipping,
        isLoadingShipping,
        isValidatingShipping,
        mutateShipping,
        isSavingConfig,
        upsertShipping,
        handleShippingAmount,
        handleShippingAmountType,
        handleConfigShippingModalOpen,
        deleteShipping,
        amountType,
        isConfigShippingModalOpen,
        shippingAmount,
        shippings,
        isLoadingShippings,
        isValidatingShippings,
        mutateShippings,
        createHistoricShipping,
        deleteHistoricShipping,
        shippingsPage,
        shippingsPageCount,
        handleShippingsPageChange,
        deleteAllShippings,
        deleteAllShippingsHistoric,
        isConfigShippingLineItemModalOpen,
        handleConfigShippingLineItemModalOpen,
        shippingSingleProductType,
        shippingDuoProductType,
        setShippingSingleProductType,
        setShippingDuoProductType,
        shippingSingleAmountType,
        shippingDuoAmountType,
        handleShippingSingleAmountType,
        handleShippingDuoAmountType,
        shippingSingleAmount,
        shippingDuoAmount,
        setShippingSingleAmount,
        setShippingDuoAmount,
      }}
    >
      {children}
    </ShippingContext.Provider>
  );
};

export const useShipping = (): IShippingProvider => {
  const context = React.useContext(ShippingContext);

  if (!context) {
    throw new Error('useShipping must be used within ShippingContext');
  }

  return context;
};
