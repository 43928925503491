import React from 'react';

import { Option as OptionIcon, TreeStructure } from 'phosphor-react';
import { useReactFlow } from 'react-flow-renderer';
import { v4 } from 'uuid';

import { ENodes } from '@domain/enums/dashboard/automations/node/ENodes';

import { useAutomation } from '@helpers/hooks/pages/dashboard/automation/useAutomation';
import { selectDefaultConditionalNodeContent } from '@helpers/utils/dashboard/automations/nodes/selectDefaultConditionalNodeContent';
import { selectDefaultSwitchNodeContent } from '@helpers/utils/dashboard/automations/nodes/selectDefaultSwithNodeContent';

import Option from '../Option';

import * as S from './styles';

const LogicTab: React.FC = () => {
  const { selectedWorkflowTriggerType, trackNode } = useAutomation();

  const reactFlowInstance = useReactFlow();

  const onDragStartConditionalNode = (event: React.DragEvent<HTMLDivElement>): void => {
    event.dataTransfer.setData('application/reactflow', ENodes.IF);
    event.dataTransfer.effectAllowed = 'move';
  };

  const onDragStartSwitchNode = (event: React.DragEvent<HTMLDivElement>): void => {
    event.dataTransfer.setData('application/reactflow', ENodes.SWITCH);
    event.dataTransfer.effectAllowed = 'move';
  };

  const handleClickConditionalNode = React.useCallback(() => {
    const rules = [];

    if (!selectedWorkflowTriggerType) return;

    rules.push(selectDefaultConditionalNodeContent(selectedWorkflowTriggerType));

    const type = ENodes.IF;

    const newNode = {
      id: v4(),
      type,
      position: {
        x: Math.random() * 500,
        y: Math.random() * 500,
      },
      data: { label: `${type}`, rules, name: v4() },
    };

    reactFlowInstance.addNodes(newNode);

    trackNode(type);
  }, [reactFlowInstance, selectedWorkflowTriggerType, trackNode]);

  const handleClickSwitchNode = React.useCallback(() => {
    const rules = [];

    if (!selectedWorkflowTriggerType) return;

    rules.push(
      selectDefaultSwitchNodeContent(selectedWorkflowTriggerType, 0),
      selectDefaultSwitchNodeContent(selectedWorkflowTriggerType, 1),
      selectDefaultSwitchNodeContent(selectedWorkflowTriggerType, 2),
    );

    const type = ENodes.SWITCH;

    const newNode = {
      id: v4(),
      type,
      position: {
        x: Math.random() * 500,
        y: Math.random() * 500,
      },
      data: { label: `${type}`, rules, name: v4() },
    };

    reactFlowInstance.addNodes(newNode);

    trackNode(type);
  }, [reactFlowInstance, selectedWorkflowTriggerType, trackNode]);

  return (
    <S.Wrapper>
      <Option
        onClick={handleClickConditionalNode}
        onDragStart={onDragStartConditionalNode}
        icon={OptionIcon}
      >
        Condição
      </Option>

      <Option
        onClick={handleClickSwitchNode}
        onDragStart={onDragStartSwitchNode}
        icon={TreeStructure}
      >
        Condição múltipla
      </Option>
    </S.Wrapper>
  );
};

export default LogicTab;
