/* eslint-disable dot-notation */
/* eslint-disable no-empty */
import Gleap from 'gleap';
import OpenReplay from '@openreplay/tracker';
import openReplayTrackerAssist from '@openreplay/tracker-assist';
import openReplayTrackerAxios from '@openreplay/tracker-axios';
import openReplayTrackerFetch from '@openreplay/tracker-fetch';
import { Analytics, AnalyticsBrowser } from '@segment/analytics-next';
import axios from 'axios';
import { format } from 'date-fns';
import React from 'react';
import { useHistory } from 'react-router-dom';
import refiner from 'refiner-js';

import { IUser } from '@domain/interfaces/IUser';
import { IConfigProvider } from '@domain/interfaces/hooks/IConfigProvider';
import { IStore } from '@domain/interfaces/stores/IStore';

import { OPEN_REPLAY_AXIOS_CONFIG, OPEN_REPLAY_FETCH_CONFIG } from '@constants/openReplayConfig';
import { ALL_PLANS, PLANS } from '@constants/plans';

import { getEnvironment } from '@helpers/utils/environment';

import trackingService from '@services/analytics/tracking';

import { DEMO_STORE_PROD_ALIAS_ID } from '@constants/demoStore';
import { useLocalStorage } from './useLocalStorage';
import { useQuery } from './useQuery';

const IS_SEGMENT_TEST = false;

const openReplayTracker = new OpenReplay({
  projectKey: 'z22RE3tJlM6Ms3E45g3Y',
  ingestPoint: 'https://openreplay.profitfy.me/ingest',
  captureIFrames: false,
});
openReplayTracker.use(openReplayTrackerAssist());
openReplayTracker.use(openReplayTrackerAxios(OPEN_REPLAY_AXIOS_CONFIG));
openReplayTracker.use(openReplayTrackerFetch(OPEN_REPLAY_FETCH_CONFIG));

const ConfigContext = React.createContext<IConfigProvider | null>(null);

export const ConfigProvider: React.FC = ({ children }) => {
  const query = useQuery();
  const history = useHistory();

  const [user, setUser] = useLocalStorage(`@profitfy:${getEnvironment()}/user`, null);
  const [appAffiliate, setAppAffiliate] = useLocalStorage(
    `@profitfy:${getEnvironment()}/app-affiliate`,
    null,
  );
  const [term, setTerm] = useLocalStorage(`@profitfy:${getEnvironment()}/term`, null);
  const [stores, setStores] = useLocalStorage(`@profitfy:${getEnvironment()}/stores`, []);
  const [authorization, setAuthorization] = useLocalStorage(
    `@profitfy:${getEnvironment()}/authorization`,
    null,
  );

  const [isDashboardDataHidden, setIsDashboardDataHidden] = useLocalStorage(
    `@profitfy:${getEnvironment()}/is-dashboard-data-hidden`,
    false,
  );

  const [personalInfo, setPersonalInfo] = useLocalStorage(
    `@profitfy:${getEnvironment()}/personal-info`,
    null,
  );

  const [affiliate, setAffiliate] = useLocalStorage(
    `@profitfy:${getEnvironment()}/affiliate`,
    null,
  );

  const [isDemoStore, setIsDemoStore] = React.useState<boolean>(false);
  const [isDownloadAppMobileModalOpen, setIsDownloadAppMobileModalOpen] = React.useState<boolean>(
    false,
  );
  const [selectedStore, setSelectedStore] = React.useState<IStore | undefined>(undefined);
  const [isMobileDevice, setIsMobileDevice] = React.useState<boolean>(false);
  const [segmentAnalytics, setSegmentAnalytics] = React.useState<Analytics | undefined>(undefined);
  const [selectedStorePlan, setSelectedStorePlan] = React.useState<
    typeof PLANS[number] | typeof ALL_PLANS[number] | undefined
  >();
  const [inviteToken, setInviteToken] = React.useState<string | undefined>(undefined);
  const [hasStartedOpenReplay, setHasStartedOpenReplay] = React.useState<boolean>(false);
  const [userOnboarding, setUserOnboarding] = React.useState<IUser | null>(null);
  // const [writeKey] = React.useState('QHkvz8Mc0CFZooVVbu6O8dUagYgXgKH8');
  const [writeKey] = React.useState('QHkvz8Mc0CFZooVVbu6O8dUagYgXgKH8');

  const isDevEnvironment = getEnvironment() === 'dev';

  const utmSource = query.get('utm_source');
  const utmMedium = query.get('utm_medium');
  const utmCampaign = query.get('utm_campaign');
  const utmContent = query.get('utm_Content');
  const utmTerm = query.get('utm_term');
  const userIdentifier = query.get('userIdentifier');

  const analytics = React.useMemo(
    () => ({
      identify(identifier: string, traits: Record<string, any>, config?: Record<string, any>) {
        if ((!isDevEnvironment && segmentAnalytics && user?.role !== 'ADMIN') || IS_SEGMENT_TEST) {
          segmentAnalytics?.identify(identifier, traits, config);
        }

        openReplayTracker.setUserID(identifier);
        Object.entries(traits).forEach(([key, value]) => {
          openReplayTracker.setMetadata(key, value);
        });
      },
      group(identifier: string, traits: Record<string, any>, config?: Record<string, any>) {
        if ((!isDevEnvironment && segmentAnalytics && user?.role !== 'ADMIN') || IS_SEGMENT_TEST) {
          segmentAnalytics?.group(identifier, traits, config);
        }
      },
      track(event: string, data?: Record<string, any>, config?: Record<string, any>) {
        if ((!isDevEnvironment && segmentAnalytics && user?.role !== 'ADMIN') || IS_SEGMENT_TEST) {
          segmentAnalytics?.track(event, data, config);
        }

        openReplayTracker.event(event, data);
      },
      async trackHubSpot(storeAliasId: string | undefined, data: Record<string, any>) {
        try {
          if (
            (!isDevEnvironment && segmentAnalytics && user?.role !== 'ADMIN') ||
            IS_SEGMENT_TEST
          ) {
            await trackingService.track(storeAliasId, data);
          }
        } catch {}
      },
      async identifyHubSpot(data: Record<string, any>) {
        try {
          if (
            (!isDevEnvironment && segmentAnalytics && user?.role !== 'ADMIN') ||
            IS_SEGMENT_TEST
          ) {
            await trackingService.identify(data);
          }
        } catch {}
      },
      reset() {
        if ((!isDevEnvironment && segmentAnalytics && user?.role !== 'ADMIN') || IS_SEGMENT_TEST) {
          segmentAnalytics?.reset();
        }
      },
      page() {
        if ((!isDevEnvironment && segmentAnalytics && user?.role !== 'ADMIN') || IS_SEGMENT_TEST) {
          segmentAnalytics?.page();
        }
      },
      pageview(page: string) {
        if ((!isDevEnvironment && segmentAnalytics) || IS_SEGMENT_TEST) {
          segmentAnalytics?.pageview(page);
        }
      },
    }),
    [segmentAnalytics, isDevEnvironment, user],
  );

  const handleDownloadAppMobileModalOpen = React.useCallback(() => {
    setIsDownloadAppMobileModalOpen(currentState => !currentState);
  }, []);

  const handleHideDashboardData = React.useCallback(() => {
    analytics?.track(
      'Instagrammable Dashboard Button Clicked',
      { user: user?.email },
      { context: { groupId: selectedStore?.alias_id } },
    );

    setIsDashboardDataHidden((currentState: boolean) => !currentState);
  }, [setIsDashboardDataHidden, analytics, user, selectedStore]);

  const handleInviteToken = React.useCallback(newInviteToken => setInviteToken(newInviteToken), []);

  const handleSelectedStorePlan = React.useCallback(plan => setSelectedStorePlan(plan), []);

  const handleSelectedStore = React.useCallback(store => setSelectedStore(store), []);

  const handleAuthorization = React.useCallback(accessToken => setAuthorization(accessToken), [
    setAuthorization,
  ]);

  const handleUser = React.useCallback(
    async newUser => {
      setUser(newUser);
    },
    [setUser],
  );

  const handleUserOnboarding = React.useCallback((userOnb: IUser) => {
    setUserOnboarding(userOnb);
  }, []);

  const handleAppAffiliate = React.useCallback(
    async newAppAffiliate => {
      setAppAffiliate(newAppAffiliate);
    },
    [setAppAffiliate],
  );

  const handleTerm = React.useCallback(
    async newTerm => {
      setTerm(newTerm);
    },
    [setTerm],
  );

  const handleAffiliate = React.useCallback(
    async newAffiliate => {
      setAffiliate(newAffiliate);
    },
    [setAffiliate],
  );

  const handlePersonalInfo = React.useCallback(
    async newPersonalInfo => {
      setPersonalInfo(newPersonalInfo);
    },
    [setPersonalInfo],
  );

  const handleStores = React.useCallback(
    newStores => {
      setStores(newStores);
    },
    [setStores],
  );

  const getUserStore = React.useCallback(
    (userStoreAliasId?: string) => {
      const foundStore = stores.find(
        (userStore: IStore) => userStore.alias_id === userStoreAliasId,
      );

      return foundStore;
    },
    [stores],
  );

  const loadAnalytics = React.useCallback(async () => {
    const [response] = await AnalyticsBrowser.load({ writeKey }, {});

    const anonymousId = userIdentifier === null ? undefined : userIdentifier;

    response.setAnonymousId(anonymousId);
    setSegmentAnalytics(response);
  }, [writeKey, userIdentifier]);

  React.useEffect(() => {
    loadAnalytics();
  }, [writeKey, loadAnalytics]);

  const handleLogout = React.useCallback(() => {
    handleAuthorization(null);
    handleUser(null);
    handlePersonalInfo(null);
    handleStores([]);
    localStorage.clear();

    history.push('/');

    return analytics?.reset();
  }, [handleAuthorization, handleUser, analytics, handlePersonalInfo, handleStores, history]);

  React.useEffect(() => {
    if (authorization) {
      axios.defaults.headers['authorization'] = authorization;
    }
  }, [authorization]);

  React.useEffect(() => {
    if (user && personalInfo) {
      analytics?.identify(
        user.id,
        {
          created_at: user.created_at ? format(new Date(user.created_at), 'dd/MM/yyyy') : undefined,
          email: user.email,
          name: `${user.first_name} ${user.last_name}`,
          status: 'active',
          birth_date: personalInfo?.birth_date
            ? format(new Date(personalInfo?.birth_date), 'dd/MM/yyyy')
            : undefined,
          role: personalInfo?.role,
          phone: personalInfo
            ? `${personalInfo?.phone_prefix}${personalInfo?.phone_number}`
            : undefined,
          position: personalInfo?.role,
        },
        {
          Intercom: {
            user_hash: user?.user_intercom_hash,
          },
        },
      );

      Gleap.identify(user.email, {
        name: `${user.first_name} ${user.last_name}`,
        email: user.email,
        phone: personalInfo
          ? `${personalInfo?.phone_prefix}${personalInfo?.phone_number}`
          : undefined,
      });
    }

    if (user) {
      analytics?.identify(user.id, {
        email: user.email,
      });
    }
  }, [analytics, personalInfo, user]);

  const trackLogin = React.useCallback(async () => {
    await analytics?.identify(
      user.id,
      {
        created_at: user.created_at ? format(new Date(user.created_at), 'dd/MM/yyyy') : undefined,
        email: user.email,
        name: `${user.first_name} ${user.last_name}`,
        status: 'active',
        birth_date: personalInfo?.birth_date
          ? format(new Date(personalInfo?.birth_date), 'dd/MM/yyyy')
          : undefined,
        role: personalInfo?.role,
        phone: personalInfo
          ? `${personalInfo?.phone_prefix}${personalInfo?.phone_number}`
          : undefined,
        position: personalInfo?.role,
      },
      {
        Intercom: {
          user_hash: user?.user_intercom_hash,
        },
      },
    );

    Gleap.identify(user.email, {
      name: `${user.first_name} ${user.last_name}`,
      email: user.email,
      phone: personalInfo
        ? `${personalInfo?.phone_prefix}${personalInfo?.phone_number}`
        : undefined,
    });

    await analytics?.track('Signed In', {
      first_login: user.created_at,
      last_login: new Date(),
      email: user?.email,
    });
  }, [user, analytics, personalInfo]);

  const trackStores = React.useCallback(async () => {
    if (user) {
      const trackStorePromise = stores?.map((store: any): any => {
        return analytics?.group(store?.alias_id, {
          created_at: store.created_at,
          name: store.name,
          currency: store.currency,
          timezone: store.iana_timezone,
          avatar: store.image_url,
          is_active: store.is_active,
          is_onboarding_finish: store.is_onboarding_finished,
          type: store?.type,
          email: user.email,
        });
      });

      await Promise.all(trackStorePromise);
    }
  }, [user, analytics, stores]);

  const startOpenReplay = React.useCallback(async () => {
    await openReplayTracker.start();
    setHasStartedOpenReplay(true);
  }, []);

  React.useEffect(() => {
    const affiliateRef = query.get('ref');

    if (affiliateRef && affiliateRef !== affiliate) {
      handleAffiliate(affiliateRef);
    }
  }, [query, handleAffiliate, affiliate]);

  React.useEffect(() => {
    const inviteTokenRef = query.get('invite_code');

    if (inviteTokenRef) setInviteToken(inviteTokenRef);
  }, [query]);

  React.useEffect(() => {
    if (user) {
      trackLogin();
      openReplayTracker.setUserID(user.id);
    }
  }, [user, trackLogin]);

  React.useEffect(() => {
    if (stores?.length) {
      trackStores();
    }
  }, [stores, trackStores]);

  React.useEffect(() => {
    startOpenReplay();
  }, [startOpenReplay]);

  React.useEffect(() => {
    if (hasStartedOpenReplay && utmSource) {
      openReplayTracker.setMetadata('utm_source', utmSource);
    }
  }, [hasStartedOpenReplay, utmSource]);

  React.useEffect(() => {
    if (hasStartedOpenReplay && utmMedium) {
      openReplayTracker.setMetadata('utm_medium', utmMedium);
    }
  }, [hasStartedOpenReplay, utmMedium]);

  React.useEffect(() => {
    if (hasStartedOpenReplay && utmCampaign) {
      openReplayTracker.setMetadata('utm_campaign', utmCampaign);
    }
  }, [hasStartedOpenReplay, utmCampaign]);

  React.useEffect(() => {
    if (hasStartedOpenReplay && utmContent) {
      openReplayTracker.setMetadata('utm_content', utmContent);
    }
  }, [hasStartedOpenReplay, utmContent]);

  React.useEffect(() => {
    if (hasStartedOpenReplay && utmTerm) {
      openReplayTracker.setMetadata('utm_term', utmTerm);
    }
  }, [hasStartedOpenReplay, utmTerm]);

  React.useEffect(() => {
    if (user) {
      refiner('setProject', 'fb7bb6e0-fed1-11eb-ab7f-058278e007dd');
      refiner('identifyUser', {
        id: user.id,
        email: user.email,
        name: `${user.first_name} ${user.last_name}`,
        signed_up_at: user.created_at,
        created_at: user.created_at ? format(new Date(user.created_at), 'dd/MM/yyyy') : undefined,
        status: 'active',
        birth_date: personalInfo?.birth_date
          ? format(new Date(personalInfo?.birth_date), 'dd/MM/yyyy')
          : undefined,
        role: personalInfo?.role,
        phone: personalInfo
          ? `${personalInfo?.phone_prefix}${personalInfo?.phone_number}`
          : undefined,
      });
    }
  }, [user, personalInfo]);

  const checkIsMobileDevice = React.useCallback((): boolean => {
    const toMatch = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;

    if (toMatch.test(navigator.userAgent)) {
      return true;
    }

    return false;
  }, []);

  React.useEffect(() => {
    const isMobile = checkIsMobileDevice();

    if (isMobile) {
      setIsMobileDevice(true);
    }
  }, [checkIsMobileDevice]);

  React.useEffect(() => {
    if (!selectedStore) return;

    if (selectedStore?.alias_id === DEMO_STORE_PROD_ALIAS_ID) setIsDemoStore(true);
  }, [selectedStore]);

  // React.useEffect(() => {
  //   if (segmentAnalytics && userIdentifier) {
  //     segmentAnalytics.setAnonymousId(userIdentifier);
  //   }
  // }, [segmentAnalytics, userIdentifier]);

  const isDemoEnvironment = window.location.host.includes('app.demo');
  const isInternalTeam = user?.role === 'ADMIN' || user?.role === 'CUSTOMER_SUCCESS';

  return (
    <ConfigContext.Provider
      value={{
        authorization,
        handleAuthorization,
        user,
        handleUser,
        personalInfo,
        handlePersonalInfo,
        handleLogout,
        stores,
        handleStores,
        getUserStore,
        selectedStore,
        handleSelectedStore,
        analytics,
        handleSelectedStorePlan,
        selectedStorePlan,
        handleAffiliate,
        affiliate,
        handleInviteToken,
        inviteToken,
        isDemoEnvironment,
        hasStartedOpenReplay,
        openReplayTracker,
        handleHideDashboardData,
        isDashboardDataHidden,
        isMobileDevice,
        handleDownloadAppMobileModalOpen,
        isDownloadAppMobileModalOpen,
        isInternalTeam,
        handleAppAffiliate,
        handleTerm,
        term,
        appAffiliate,
        isDemoStore,
        handleUserOnboarding,
        userOnboarding,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};

export const useConfig = (): IConfigProvider => {
  const context = React.useContext(ConfigContext);

  if (!context) {
    throw new Error('useConfig must be used within ConfigProvider');
  }

  return context;
};
