import styled from 'styled-components/macro';

import ModalBase from '@components/common/core/DataDisplay/Modal';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import LabelBase from '@components/common/core/DataDisplay/Label';
import TextFieldBase from '@components/common/core/Inputs/TextField';
import TextBase from '@components/common/core/DataDisplay/Text';
import ButtonBase from '@components/common/core/Inputs/Button';
import SelectBase from '@components/common/core/Inputs/Select';
import OptionBase from '@components/common/core/Inputs/Select/Option';

export const Option = styled(OptionBase)``;

export const Modal = styled(ModalBase)`
  width: 100%;
  max-width: 558px;
  box-sizing: border-box;
`;

export const HeadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
`;

export const Heading = styled(TextBase)`
  font-size: 1.125rem;
`;

export const ItemQuantityWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  border: 1px solid ${({ theme }) => theme.colors.gray[5]};
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 16px;
`;

export const InputGroup = styled(InputGroupBase)<{ flexNumber?: number }>`
  ${({ flexNumber }) => flexNumber && `flex: ${flexNumber};`}
  margin-bottom: 24px;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  gap: 4px;
`;

export const Label = styled(LabelBase)``;

export const Input = styled(TextFieldBase)`
  flex-grow: 1;
  flex: 2;
`;

export const Select = styled(SelectBase)`
  width: 100%;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;
  margin-left: auto;
  margin-top: 16px;
`;

export const CancelButton = styled.button`
  border: unset;
  background-color: unset;
  /* font-family: 'Poppins'; */
  font-family: 'Mukta';
  font-weight: 500;
  font-size: 0.875rem;
  color: #ffffff;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const ConfirmButton = styled(ButtonBase)``;
