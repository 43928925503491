import styled, { css } from 'styled-components/macro';

import SidemodalBase from '@components/common/core/DataDisplay/SideModal';
import HeadingBase from '@components/common/core/DataDisplay/Heading';
import DateRangePickerBase from '@components/common/core/Utils/CustomDatePicker';
import InputGroupBase from '@components/common/core/Inputs/InputGroup';
import LabelBase from '@components/common/core/DataDisplay/Label';
import SelectBase from '@components/common/core/Inputs/Select';
import OptionBase from '@components/common/core/Inputs/Select/Option';
import TextBase from '@components/common/core/DataDisplay/Text';
import TextFieldBase from '@components/common/core/Inputs/TextField';
import { EHeadAlign } from '@domain/enums/components/ETable';
import ButtonBase from '@components/common/core/Inputs/Button';

export const Option = styled(OptionBase)``;

export const Select = styled(SelectBase)`
  width: 100%;
`;

export const Input = styled(TextFieldBase)``;

export const ErrorWrapper = styled.div`
  margin: 128px 0;
`;

export const Sidemodal = styled(SidemodalBase)`
  max-width: 588px;
  box-sizing: border-box;
  width: 100%;
`;

export const ContentWrapper = styled.div`
  text-align: left;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  margin-bottom: 76px;
`;

export const Title = styled(HeadingBase)``;

export const HistoricText = styled(TextBase)``;

export const Label = styled(LabelBase)``;

export const InputGroup = styled(InputGroupBase)`
  margin-bottom: 24px;
`;

export const DateRangePicker = styled(DateRangePickerBase)`
  & > div:first-child {
    margin-bottom: 0;
  }
`;

export const Body = styled.div`
  margin-top: 24px;
`;

export const HistoricBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 24px;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
`;

export const TableWrapper = styled.div`
  padding: 0;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    height: 6px;
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.background[5]};
    border-radius: 25px;
    margin-bottom: 4px;
  }
`;

export const Table = styled.table`
  width: 100%;
  min-width: max-content;
  border-collapse: collapse;
`;

export const TableHeader = styled.thead``;

export const TableHead = styled.th`
  text-align: left;
  font-family: ${({ theme }) => theme.fonts.heading};
  font-weight: 500;
  font-size: 1.125rem;
  color: ${({ theme }) => theme.colors.font};
  padding: 0 12px 18px 12px;

  &:first-child {
    padding-left: 0px;
  }

  ${({ align }) =>
    align === EHeadAlign.CENTER &&
    css`
      text-align: center;
    `};

  ${({ align }) =>
    align === EHeadAlign.RIGHT &&
    css`
      text-align: right;
    `};
`;

export const TableBody = styled.tbody``;

export const TableRow = styled.tr``;

export const TableData = styled.td`
  text-align: left;
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.font};
  font-weight: 400;
  padding: 0 12px 24px 12px;

  &:first-child {
    padding-left: 0px;
  }

  ${({ align }) =>
    align === EHeadAlign.CENTER &&
    css`
      text-align: center;
    `};

  ${({ align }) =>
    align === EHeadAlign.RIGHT &&
    css`
      text-align: right;
    `};
`;

export const TableFooter = styled.tfoot``;

export const SubmitButton = styled(ButtonBase)`
  margin-top: 32px;
  margin-bottom: 32px;
`;
