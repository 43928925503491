import styled, { css } from 'styled-components/macro';

import { IShippingOptionProps } from '@domain/interfaces/dashboard/Shipping/IShippingOption';

import BadgeBase from '@components/common/core/DataDisplay/Badge';
import HeadingBase from '@components/common/core/DataDisplay/Heading';
import TextBase from '@components/common/core/DataDisplay/Text';
import RadioBase from '@components/common/core/Inputs/Radio';
import ButtonBase from '@components/common/core/Inputs/Button';
import CustomTooltipBase from '@components/common/core/Utils/CustomTooltip';
import CustomPopoverBase from '@components/common/core/Utils/CustomPopover';

export const Radio = styled(RadioBase)`
  & > span {
    background-color: ${({ theme, disabled }) =>
      disabled ? theme.colors.gray[3] : theme.colors.gray[7]};
  }
`;

export const ContentWrapper = styled.div`
  width: 100%;
  max-width: 554px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 48px;
  gap: 16px;
`;

export const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Title = styled(HeadingBase)`
  color: #ffffff;
`;

export const SubTitle = styled(TextBase)``;

export const Body = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ShippingOption = styled.div<IShippingOptionProps>`
  width: 100%;
  height: 69px;
  background-color: ${({ theme }) => theme.colors.gray[6]};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px 24px;
  box-sizing: border-box;

  ${({ isIntegrationOption }) =>
    isIntegrationOption &&
    css`
      height: unset;
      padding: 24px;
    `}

  ${({ isAverageByOrderOption }) =>
    isAverageByOrderOption &&
    css`
      @media only screen and (max-width: 490px) {
        height: unset;
      }
    `}
`;

export const ShippingOptionContent = styled.div<IShippingOptionProps>`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;

  ${({ isAverageByOrderOption }) =>
    isAverageByOrderOption &&
    css`
      justify-content: space-between;

      @media only screen and (max-width: 490px) {
        flex-direction: column;
        align-items: flex-start;
      }
    `}
`;

export const RadioAndShippingTextWrapper = styled.div`
  display: flex;
  gap: 16px;
`;

export const ShippingOptionText = styled(TextBase)`
  color: #ffffff;
  line-height: 130%;
`;

export const TextAndDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: auto;
`;

export const AverageByOrderValueBadge = styled(BadgeBase)``;

export const SetValueButton = styled.button`
  border: unset;
  background-color: unset;
  padding: unset;
  /* font-family: 'Poppins'; */
  font-family: 'Mukta';
  font-weight: 500;
  font-size: 1rem;
  color: ${({ color }) => color};
  cursor: pointer;
`;

export const Description = styled(TextBase)`
  color: ${({ theme }) => theme.colors.gray[2]};
`;

export const HorizontalRule = styled.hr`
  border: 1px solid ${({ theme }) => theme.colors.gray[5]};
  width: 100%;
  margin-top: 20px;
  margin-bottom: 24px;
`;

export const ShippingIntegrationsWrapper = styled.div`
  width: 100%;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.black.light};
`;

export const Integration = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 32px;
  box-sizing: border-box;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[5]};

  &:last-child {
    border-bottom: unset;
  }
`;

export const IntegrationContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

export const IntegrationName = styled(TextBase)`
  color: ${({ theme }) => theme.colors.gray[2]};
`;

export const Badge = styled(BadgeBase)`
  background-color: rgba(187, 187, 187, 0.3);
  color: ${({ theme }) => theme.colors.gray[2]};
`;

export const SaveConfigButton = styled(ButtonBase)`
  margin-left: auto;
`;

export const Tooltip = styled(CustomTooltipBase)``;

export const CustomPopover = styled(CustomPopoverBase)``;

export const ActionButton = styled.button`
  border: none;
  background-color: transparent;
  padding: 0;
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.font};
  cursor: pointer;
  transition: color 0.3s;
  position: relative;
  width: 100%;
  padding: 8px 16px;
  text-align: left;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  gap: 12px;
  font-weight: 500;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray[6]};
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.gray[6]};
    filter: brightness(0.9);
  }
`;

export const MoreOptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 0;
`;
