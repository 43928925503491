import React from 'react';
import { ChevronRight } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';
import { useParams } from 'react-router-dom';
import { DotsThree, DotsThreeVertical, Truck, CalendarPlus } from 'phosphor-react';

import { EHeadingWeight } from '@domain/enums/components/EHeading';
import { ETextSize } from '@domain/enums/components/EText';
import { EShippingAmountType, EShippingType } from '@domain/enums/dashboard/shipping/EShipping';
import { EBadgeColors } from '@domain/enums/components/EBadge';
import { IParams } from '@domain/interfaces/IParams';

import { useShipping } from '@helpers/hooks/pages/dashboard/shipping/useShipping';
import { useConfig } from '@helpers/hooks/useConfig';
import { useHome } from '@helpers/hooks/pages/dashboard/home/useHome';
import { useToast } from '@helpers/hooks/useToast';

import { SHIPPING_INTEGRATIONS } from '@constants/pages/dashboard/shipping/shipping';

import ConfigShippingModal from './ConfigShippingModal';
import SkeletonLoading from './SkeletonLoading';
import HistoricShippingSideBar from './HistoricShippingSideModal';
import ConfigShippingLineItemModal from './ConfigShippingLineItemModal';

import * as S from './styles';

const Shipping: React.FC = () => {
  const theme = useTheme();
  const { storeAliasId } = useParams<IParams>();
  const { analytics, user } = useConfig();
  const { toast } = useToast();

  const {
    isSavingConfig,
    amountType,
    isLoadingShipping,
    isValidatingShipping,
    shippingAmount,
    shipping,
    isConfigShippingModalOpen,
    handleConfigShippingModalOpen,
    upsertShipping,
    deleteShipping,
    deleteAllShippings,
    deleteAllShippingsHistoric,
    isConfigShippingLineItemModalOpen,
    handleConfigShippingLineItemModalOpen,
  } = useShipping();
  const { mutateHomeSetup } = useHome();

  const [shippingType, setShippingType] = React.useState<string>('');
  const [showHistoricShipping, setShowHistoricShipping] = React.useState<boolean>(false);
  const [showPopover, setShowPopover] = React.useState<boolean>(false);
  const [showLineItemPopover, setShowLineItemPopover] = React.useState<boolean>(false);

  const onRadioChange = React.useCallback(event => {
    setShippingType(event.target.value);
  }, []);

  const toggleHistoricShipping = React.useCallback(() => {
    setShowHistoricShipping(prev => !prev);
  }, []);

  const togglePopover = React.useCallback(() => {
    setShowPopover(prev => !prev);
  }, []);

  const toggleLineItemPopover = React.useCallback(() => {
    setShowLineItemPopover(prev => !prev);
  }, []);

  const onSave = React.useCallback(async () => {
    if (!shippingType) {
      deleteAllShippings();
    } else if (shippingType === EShippingType.PAID_BY_CUSTOMER) {
      deleteAllShippingsHistoric();
      upsertShipping(shippingType as EShippingType);
    } else if (shippingType === EShippingType.LINE_ITEM_QUANTITY) {
      deleteAllShippingsHistoric();
      upsertShipping(shippingType as EShippingType);
    } else {
      upsertShipping(shippingType as EShippingType);
    }

    analytics?.track(
      'Shipping Setup Save Configuration Button Clicked',
      { email: user?.email, click_source: shippingType || 'NONE' },
      { context: { groupId: storeAliasId } },
    );

    mutateHomeSetup();
  }, [
    upsertShipping,
    shippingType,
    analytics,
    user,
    storeAliasId,
    mutateHomeSetup,
    deleteAllShippings,
    deleteAllShippingsHistoric,
  ]);

  const getAverageByOrderBadgeText = (): string => {
    if (amountType === EShippingAmountType.FIXED) {
      return `R$ ${shippingAmount}`;
    }

    return `${shippingAmount}%`;
  };

  React.useEffect(() => {
    if (shipping) {
      setShippingType(shipping.type);
    }
  }, [shipping]);

  React.useEffect(() => {
    analytics?.track(
      'Shipping Setup Page Viewed',
      { email: user?.email },
      { context: { groupId: storeAliasId } },
    );
  }, [analytics, user, storeAliasId]);

  const isAverageByOrderOption =
    shippingType === EShippingType.AVERAGE_BY_ORDER ||
    shipping?.type === EShippingType.AVERAGE_BY_ORDER;

  const isLineItemQuantityOption =
    shippingType === EShippingType.LINE_ITEM_QUANTITY ||
    shipping?.type === EShippingType.LINE_ITEM_QUANTITY;

  const averageByOrderBadgeText = shippingAmount
    ? getAverageByOrderBadgeText()
    : 'Valor não definido';

  if (isLoadingShipping || isValidatingShipping) {
    return <SkeletonLoading />;
  }

  return (
    <S.ContentWrapper>
      <S.HeadingWrapper>
        <S.Title fontWeight={EHeadingWeight.REGULAR}>Deseja configurar seu frete?</S.Title>
        <S.SubTitle>Selecione como seu frete será contabilizado</S.SubTitle>
      </S.HeadingWrapper>

      <S.Body>
        <S.ShippingOption>
          <S.ShippingOptionContent>
            <S.Radio
              name="shipping-option"
              value=""
              defaultChecked={!shipping?.type}
              id="shipping-option"
              onChange={onRadioChange}
            />

            <S.ShippingOptionText>Nenhuma configuração de frete</S.ShippingOptionText>
          </S.ShippingOptionContent>
        </S.ShippingOption>

        <S.ShippingOption isAverageByOrderOption={isAverageByOrderOption}>
          <S.ShippingOptionContent isAverageByOrderOption={isAverageByOrderOption}>
            <S.RadioAndShippingTextWrapper>
              <S.Radio
                name="shipping-option"
                value={EShippingType.AVERAGE_BY_ORDER}
                defaultChecked={shipping?.type === EShippingType.AVERAGE_BY_ORDER}
                id="shipping-option"
                onChange={onRadioChange}
              />

              {isAverageByOrderOption && (
                <S.ShippingOptionText>Frete médio por venda</S.ShippingOptionText>
              )}
            </S.RadioAndShippingTextWrapper>

            {!isAverageByOrderOption && (
              <S.TextAndDescriptionWrapper>
                <S.ShippingOptionText>Frete médio por venda</S.ShippingOptionText>

                <S.Description size={ETextSize.B5}>
                  Para cada venda aprovada, será adicionado um custo fixo de frete definido
                </S.Description>
              </S.TextAndDescriptionWrapper>
            )}

            {isAverageByOrderOption && (
              <>
                <S.AverageByOrderValueBadge
                  color={shippingAmount ? EBadgeColors.PRIMARY : EBadgeColors.YELLOW}
                >
                  {averageByOrderBadgeText}
                </S.AverageByOrderValueBadge>

                <S.Tooltip>
                  <S.Tooltip.Trigger>
                    <S.CustomPopover open={showPopover} onOpenChange={togglePopover}>
                      <S.CustomPopover.Trigger>
                        <DotsThreeVertical size={20} color="#FFF" />
                      </S.CustomPopover.Trigger>
                      <S.CustomPopover.Content>
                        <S.MoreOptionWrapper>
                          <S.ActionButton
                            onClick={e => {
                              e.preventDefault();
                              togglePopover();
                              handleConfigShippingModalOpen();
                            }}
                          >
                            <Truck size={16} color={theme.colors.font} />
                            {shippingAmount ? 'Editar' : 'Definir valor'}
                          </S.ActionButton>
                          <S.ActionButton
                            onClick={() => {
                              if (!shippingAmount) {
                                toast.error(
                                  'É necessário definir um valor de frete para visualizar o histórico',
                                );
                                return;
                              }

                              togglePopover();
                              toggleHistoricShipping();
                            }}
                          >
                            <CalendarPlus size={16} color={theme.colors.font} />
                            Histórico de Frete
                          </S.ActionButton>
                        </S.MoreOptionWrapper>

                        <S.CustomPopover.Arrow />
                      </S.CustomPopover.Content>
                    </S.CustomPopover>
                  </S.Tooltip.Trigger>
                </S.Tooltip>

                {/* <S.SetValueButton
                  color={shippingAmount ? '#ffffff' : theme.colors.green.default}
                  onClick={handleConfigShippingModalOpen}
                >
                  {shippingAmount ? 'Editar' : 'Definir valor'}
                </S.SetValueButton> */}
              </>
            )}
          </S.ShippingOptionContent>
        </S.ShippingOption>

        <S.ShippingOption>
          <S.ShippingOptionContent>
            <S.Radio
              name="shipping-option"
              value={EShippingType.PAID_BY_CUSTOMER}
              defaultChecked={shipping?.type === EShippingType.PAID_BY_CUSTOMER}
              id="shipping-option"
              onChange={onRadioChange}
            />

            <S.TextAndDescriptionWrapper>
              <S.ShippingOptionText>Frete pago pelo cliente no checkout</S.ShippingOptionText>

              <S.Description size={ETextSize.B5}>
                Utiliza frete que cliente paga no checkout, como custo de frete
              </S.Description>
            </S.TextAndDescriptionWrapper>
          </S.ShippingOptionContent>
        </S.ShippingOption>

        <S.ShippingOption>
          <S.ShippingOptionContent>
            <S.Radio
              name="shipping-option"
              value={EShippingType.LINE_ITEM_QUANTITY}
              defaultChecked={shipping?.type === EShippingType.LINE_ITEM_QUANTITY}
              id="shipping-option"
              onChange={onRadioChange}
            />

            {/* {isLineItemQuantityOption && ( */}
            <S.TextAndDescriptionWrapper>
              <S.ShippingOptionText>
                Frete configurável pela quantidade de produtos
              </S.ShippingOptionText>

              <S.Description size={ETextSize.B5}>
                Utilize valores diferentes para cada quantidade de produtos
              </S.Description>
            </S.TextAndDescriptionWrapper>
            {/* )} */}

            {isLineItemQuantityOption && (
              <>
                <S.Tooltip>
                  <S.Tooltip.Trigger>
                    <S.CustomPopover
                      open={showLineItemPopover}
                      onOpenChange={toggleLineItemPopover}
                    >
                      <S.CustomPopover.Trigger>
                        <DotsThreeVertical size={20} color="#FFF" />
                      </S.CustomPopover.Trigger>
                      <S.CustomPopover.Content>
                        <S.MoreOptionWrapper>
                          <S.ActionButton
                            onClick={e => {
                              e.preventDefault();
                              toggleLineItemPopover();
                              handleConfigShippingLineItemModalOpen();
                            }}
                          >
                            <Truck size={16} color={theme.colors.font} />
                            {/* {shippingAmount ? 'Editar' : 'Definir valor'} */}
                            Editar
                          </S.ActionButton>
                        </S.MoreOptionWrapper>

                        <S.CustomPopover.Arrow />
                      </S.CustomPopover.Content>
                    </S.CustomPopover>
                  </S.Tooltip.Trigger>
                </S.Tooltip>

                {/* <S.SetValueButton
                  color={shippingAmount ? '#ffffff' : theme.colors.green.default}
                  onClick={handleConfigShippingModalOpen}
                >
                  {shippingAmount ? 'Editar' : 'Definir valor'}
                </S.SetValueButton> */}
              </>
            )}
          </S.ShippingOptionContent>
        </S.ShippingOption>

        <S.ShippingOption isIntegrationOption>
          <S.ShippingOptionContent>
            <S.Radio
              name="shipping-option"
              value={EShippingType.INTEGRATION}
              defaultChecked={shipping?.type === EShippingType.INTEGRATION}
              id="shipping-option"
              onChange={onRadioChange}
              disabled
            />

            <S.TextAndDescriptionWrapper>
              <S.ShippingOptionText>Integração</S.ShippingOptionText>

              <S.Description size={ETextSize.B5}>
                Integração de cálculo de frete ainda não disponivel
              </S.Description>
            </S.TextAndDescriptionWrapper>
          </S.ShippingOptionContent>

          <S.HorizontalRule />

          <S.ShippingIntegrationsWrapper>
            {SHIPPING_INTEGRATIONS.map(integration => {
              const Icon = integration.icon;

              return (
                <S.Integration key={integration.name}>
                  <S.IntegrationContent>
                    <Icon size={30} />

                    <S.IntegrationName>{integration.name}</S.IntegrationName>

                    <S.Badge color="">Em breve</S.Badge>
                  </S.IntegrationContent>

                  <ChevronRight size={18} color={theme.colors.gray[4]} />
                </S.Integration>
              );
            })}
          </S.ShippingIntegrationsWrapper>
        </S.ShippingOption>
      </S.Body>

      <S.SaveConfigButton onClick={onSave} isLoading={isSavingConfig} disabled={isSavingConfig}>
        Salvar configuração de frete
      </S.SaveConfigButton>

      <ConfigShippingModal
        isOpen={isConfigShippingModalOpen}
        toggle={handleConfigShippingModalOpen}
      />

      <ConfigShippingLineItemModal
        isOpen={isConfigShippingLineItemModalOpen}
        toggle={handleConfigShippingLineItemModalOpen}
      />

      {showHistoricShipping && (
        <HistoricShippingSideBar isOpen={showHistoricShipping} toggle={toggleHistoricShipping} />
      )}
    </S.ContentWrapper>
  );
};

export default Shipping;
