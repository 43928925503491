import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useShipping } from '@helpers/hooks/pages/dashboard/shipping/useShipping';
import { useDate } from '@helpers/hooks/useDate';
import { useToast } from '@helpers/hooks/useToast';
import { IPeriod } from '@domain/interfaces/dashboard/ProductCostDetails/IHistoricSidemodal';
import { EShippingAmountType, EShippingType } from '@domain/enums/dashboard/shipping/EShipping';
import { currencyFormatter } from '@helpers/masks';
import Text from '@components/common/core/DataDisplay/Text';
import Form from '@components/common/core/Inputs/Form';
import Paginate from '@components/common/core/Utils/Paginate';
import { EHeadAlign } from '@domain/enums/components/ETable';

import Row from './Row';

import * as S from './styles';

interface IHistoricShippingSideModalProps {
  isOpen: boolean;
  toggle: () => void;
}

const formSchema = yup.object().shape({
  cost: yup.string().required('É necessário informar um valor.'),
});

const HistoricShippingSideModal: React.FC<IHistoricShippingSideModalProps> = ({
  isOpen,
  toggle,
}) => {
  const { utcToZonedTime } = useDate();
  const {
    shippings,
    createHistoricShipping,
    shippingsPageCount,
    handleShippingsPageChange,
    shippingsPage,
  } = useShipping();
  const { toast } = useToast();

  const [amountType, setAmountType] = React.useState<EShippingAmountType>(
    EShippingAmountType.FIXED,
  );

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ mode: 'onBlur', resolver: yupResolver(formSchema) });

  const [period, setPeriod] = React.useState<IPeriod>({
    startDate: utcToZonedTime(new Date()),
    endDate: utcToZonedTime(new Date()),
  });

  const costInput = register('cost');
  const amountTypeInput = register('amount-type');

  const onSubmit = React.useCallback(
    async data => {
      const averageShipping = shippings.find(s => s.type === EShippingType.AVERAGE_BY_ORDER);

      if (!averageShipping) {
        toast.error(
          'É necessário salvar a configuração frete médio por venda primeiro antes de adicionar um histórico.',
        );
        return;
      }

      try {
        await createHistoricShipping({
          amount: data.cost,
          type: amountType,
          startDate: period.startDate,
          endDate: period.endDate,
          shippingType: EShippingType.AVERAGE_BY_ORDER,
        });
      } catch (err) {
        console.log(err);
      }
    },
    [period, amountType, createHistoricShipping, shippings, toast],
  );

  const maxDate = utcToZonedTime(new Date());

  return (
    <S.Sidemodal isOpen={isOpen} toggle={toggle}>
      <S.Header>
        <S.Title>Histórico de Frete</S.Title>
      </S.Header>

      <S.Body>
        <S.HistoricBody>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <>
              <S.InputGroup>
                <S.Label>Tipo</S.Label>

                <S.Select
                  {...amountTypeInput}
                  name="amount-type"
                  onChange={event => {
                    if (!event?.target?.value) return;

                    amountTypeInput.onChange(event);

                    setAmountType(event.target.value as EShippingAmountType);
                  }}
                  value={amountType}
                >
                  <S.Option value={EShippingAmountType.FIXED}>Fixo</S.Option>
                  <S.Option value={EShippingAmountType.PERCENTAGE}>Porcentagem</S.Option>
                </S.Select>
              </S.InputGroup>

              <S.InputGroup>
                <S.Label>Valor de frete por venda concluída</S.Label>
                <S.Input
                  {...costInput}
                  type="text"
                  // value={amount}
                  name="cost"
                  noFeedbackError
                  onChange={event => {
                    costInput.onChange(event);

                    const { value } = event.target;

                    event.target.value = currencyFormatter(value);
                  }}
                  placeholder="Digite o valor do frete"
                  prependText={amountType === EShippingAmountType.FIXED ? 'R$' : '%'}
                />
                {errors.cost && <Text isErrorFeedback>{errors.cost.message}</Text>}
              </S.InputGroup>

              <S.InputGroup>
                <S.DateRangePicker period={period} setPeriod={setPeriod} maxDate={maxDate} />
              </S.InputGroup>

              <S.SubmitButton type="submit" isLoading={false} disabled={false}>
                Adicionar histórico
              </S.SubmitButton>
            </>
          </Form>
        </S.HistoricBody>

        <S.TableWrapper>
          <S.Table>
            <S.TableHeader>
              <S.TableRow>
                <S.TableHead>Data Inicial</S.TableHead>
                <S.TableHead>Data Final</S.TableHead>
                <S.TableHead align={EHeadAlign.RIGHT}>Custo</S.TableHead>
                <S.TableHead align={EHeadAlign.CENTER}>Ações</S.TableHead>
              </S.TableRow>
            </S.TableHeader>

            <S.TableBody>
              {shippings?.map(shipping => (
                <Row shipping={shipping} key={shipping.alias_id} />
              ))}
            </S.TableBody>

            <S.TableFooter>
              <S.TableRow>
                <S.TableData colSpan={4} align={EHeadAlign.RIGHT}>
                  <Paginate
                    forcePage={shippingsPage}
                    pageCount={shippingsPageCount}
                    onPageChange={handleShippingsPageChange}
                  />
                </S.TableData>
              </S.TableRow>
            </S.TableFooter>
          </S.Table>
        </S.TableWrapper>
      </S.Body>
    </S.Sidemodal>
  );
};

export default HistoricShippingSideModal;
