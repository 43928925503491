import React from 'react';

import { EButtonVariant } from '@domain/enums/components/EButton';

import * as S from './styles';

interface ISaveProps {
  onDelete: () => void;
  hasCredentials: boolean;
  isSubmitting: boolean;
  isDeleting: boolean;
  isDeleteButtonDisabled: boolean;
  isSaveButtonDisabled: boolean;
  onSubmit: () => void;
}

const Save: React.FC<ISaveProps> = ({
  onDelete,
  onSubmit,
  hasCredentials = false,
  isSubmitting,
  isDeleting,
  isDeleteButtonDisabled,
  isSaveButtonDisabled,
}) => {
  return (
    <S.Background>
      <S.SaveWrapper>
        {hasCredentials && (
          <S.DisableIntegrationButton
            onClick={onDelete}
            variant={EButtonVariant.SECONDARY}
            isLoading={isDeleting}
            disabled={isDeleteButtonDisabled}
          >
            Desativar integração
          </S.DisableIntegrationButton>
        )}

        {!hasCredentials && (
          <S.ConfirmButton
            type="submit"
            isLoading={isSubmitting}
            disabled={isSaveButtonDisabled}
            onClick={onSubmit}
          >
            Integrar Zouti
          </S.ConfirmButton>
        )}
      </S.SaveWrapper>
    </S.Background>
  );
};

export default Save;
